import { debounce } from 'lodash';
import {
  arrayOf,
  bool,
  func,
  node,
  number,
  oneOf,
  shape,
  string,
} from 'prop-types';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import FilterSelect from '../../../../app/components/common/FilterSelect';
import { PaginationPanel as GenericPagination } from '../../../../app/components/common/PaginationPanel';
import AwesomeButtonGroup from '../../../../app/components/interface/buttons/AwesomeButtonGroup';
import { NAME_MAPPING, translateStatusFilter } from './util';

const SearchInputWrapper = styled('div')`
  position: relative;
  &:after {
    content: '\f002';
    font-family: 'Font Awesome\ 5 Pro', sans-serif;
    font-size: 17px;
    color: #bbbcbf;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 20px;
  }
`;

const debouncedSearch = debounce((value, onChange) => {
  onChange(value);
}, 1000);

function StatusSearch({ onChange, value, disabled }) {
  const [localValue, setLocalValue] = useState(value);

  useEffect(() => {
    debouncedSearch.cancel();
    debouncedSearch(localValue, onChange);
  }, [localValue]);

  return (
    // For some reason styled does not persist events on the input, thus denying access to e.target
    <SearchInputWrapper>
      <input
        className="new-status-tab__search-input"
        placeholder="Search"
        onChange={(e) => setLocalValue(e.target.value)}
        value={localValue}
        disabled={disabled}
      />
    </SearchInputWrapper>
  );
}

StatusSearch.propTypes = {
  onChange: func.isRequired,
  value: string.isRequired,
  disabled: bool.isRequired,
};

function StatusFilter({
  selectedStatus,
  setSelectedStatus,
  statusCounts,
  disabled,
}) {
  return (
    <FilterSelect
      title="Select Status"
      selected={selectedStatus}
      list={Object.entries(NAME_MAPPING).map(([value, label]) => ({
        label: translateStatusFilter(label),
        value,
        count: statusCounts[value],
      }))}
      resetThenSet={setSelectedStatus}
      disabled={disabled}
    />
  );
}

StatusFilter.defaultProps = {
  statusCounts: {
    ALL: 0,
    PENDING: 0,
    CHANGES_PENDING: 0,
    INCOMPATIBLE: 0,
    PASS: 0,
    AVAILABLE: 0,
    REMEDIATED: 0,
    ERROR: 0,
    EXCLUDED: 0,
  },
};

StatusFilter.propTypes = {
  selectedStatus: oneOf([...Object.keys(NAME_MAPPING)]).isRequired,
  setSelectedStatus: func.isRequired,
  statusCounts: shape({
    ALL: number,
    PENDING: number,
    CHANGES_PENDING: number,
    INCOMPATIBLE: number,
    PASS: number,
    AVAILABLE: number,
    REMEDIATED: number,
    ERROR: number,
    EXCLUDED: number,
  }),
  disabled: bool.isRequired,
};

function SizePerPageDropdown({
  sizePerPageList,
  currSizePerPage,
  changeSizePerPage,
  disabled,
}) {
  return (
    <AwesomeButtonGroup
      options={sizePerPageList}
      numerableOptions
      selected={+currSizePerPage}
      onChange={changeSizePerPage}
      label="Results Per Page"
      disabled={disabled}
    />
  );
}

SizePerPageDropdown.propTypes = {
  sizePerPageList: arrayOf(number).isRequired,
  currSizePerPage: number.isRequired,
  changeSizePerPage: func.isRequired,
  disabled: bool,
};

SizePerPageDropdown.defaultProps = {
  disabled: false,
};

function PaginationPanel({
  currPage,
  sizePerPage,
  components: { sizePerPageDropdown },
  resultsString,
  dataSize,
  changePage,
}) {
  return (
    <GenericPagination
      sizePerPageNode={sizePerPageDropdown}
      sizePerPage={sizePerPage}
      totalItems={dataSize}
      currPage={currPage}
      lastPage={Math.ceil(dataSize / sizePerPage)}
      labelName={resultsString}
      changeFilterInUrl={({ page }) => changePage(page)}
    />
  );
}

PaginationPanel.propTypes = {
  currPage: number.isRequired,
  sizePerPage: number.isRequired,
  components: shape({
    sizePerPageDropdown: node.isRequired,
  }).isRequired,
  resultsString: string.isRequired,
  dataSize: number.isRequired,
  changePage: func.isRequired,
};

function ExpandColumnComponent({ isExpandableRow, isExpanded }) {
  if (!isExpandableRow) {
    return '';
  }

  return (
    <i
      className={
        isExpanded
          ? 'fas fa-caret-down c-grey-500'
          : 'fas fa-caret-right c-grey-999'
      }
    />
  );
}

ExpandColumnComponent.propTypes = {
  isExpandableRow: bool.isRequired,
  isExpanded: bool.isRequired,
};

export {
  StatusSearch,
  StatusFilter,
  SizePerPageDropdown,
  PaginationPanel,
  ExpandColumnComponent,
};
