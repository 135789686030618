import FeatureFlags from 'src/config/feature-flags';

export const devices = {
  MAC: 'Mac',
  IPHONE: 'iPhone',
  IPAD: 'iPad',
  TV: 'Apple TV',
  VISION: 'Vision',
  IPOD: 'iPod',
  WATCH: 'watch',
};

export const deviceOptions = [
  { value: devices.MAC, label: 'Mac' },
  { value: devices.IPHONE, label: 'iPhone' },
  { value: devices.IPAD, label: 'iPad' },
  { value: devices.TV, label: 'Apple TV' },
  { value: devices.VISION, label: 'Vision' },
];

export const devicesData = {
  [devices.MAC]: {
    commonKey: 'runs_on_mac',
    profileKey: 'min_mac_os_version_regexp',
    regexp: '^(?:1[1-9](?:\\.\\d+)+|10\\.(?:[1-9][1-9])(?:\\.\\d+)?)$',
    label: 'Mac',
  },
  [devices.IPHONE]: {
    commonKey: 'runs_on_iphone',
    profileKey: 'min_ios_version_regexp',
    regexp: '^(?:1[1-9]|[2-9]\\d)\\.\\d(?:\\.\\d+)?$',
    label: 'iPhone',
  },
  [devices.IPAD]: {
    commonKey: 'runs_on_ipad',
    profileKey: 'min_ipad_os_version_regexp',
    regexp: '^(?:1[1-9]|[2-9]\\d)\\.\\d(?:\\.\\d+)?$',
    label: 'iPad',
  },
  [devices.TV]: {
    commonKey: 'runs_on_tv',
    profileKey: 'min_tv_os_version_regexp',
    regexp: '^(?:1[1-9]|[2-9]\\d)\\.\\d(?:\\.\\d+)?$',
    label: 'Apple TV',
  },

  //TODO need the profile key here
  [devices.VISION]: {
    commonKey: 'runs_on_vision',
    profileKey: 'min_vision_os_version_regexp',
    regexp: '^(?:1\\d)\\.\\d(?:\\.\\d+)?$',
    label: 'Vision',
  },

  [devices.IPOD]: {
    commonKey: 'runs_on_ipod',
  },
  [devices.WATCH]: {
    commonKey: 'runs_on_watch',
  },
};

export const getItemConfigDevices = (itemType, deviceList = []) => {
  const usesItemConfigDevices = {
    'dep-profile': true,
  };

  if (usesItemConfigDevices[itemType]) {
    return [...new Set(deviceList)];
  }

  return false;
};

/**
 * Receives a list of devices and tranforms them into { label, value } format.
 * @param {Array} deviceList - an Array of device names (ex. ['Mac', 'iPhone', 'Apple TV'])
 * @returns a list of devices in { label, value } format
 *   ex. [
 *         { label: 'Mac', value; 'Mac' },
 *         { label: 'iPhone, value: 'iPhone' },
 *         { label: 'Apple TV', value: 'Apple TV' },
 *       ] */
export const formatDeviceOptions = (deviceList) =>
  deviceList.map((device) => ({ label: device, value: device }));

/**
 * Populates a model devices with default values from the library item config if no initial
 * devices are provided (particularly relevant in the case of library item creation)
 * @param {Object} model - a library item model
 * @param {function} setModel - a callback to update the library item model
 * @param {Object} itemConfig - a static library item config with a `devices` property
 */
export const populateSelectedDevices = (model, setModel, itemConfig) => {
  // Populate the model with default install on devices if none are provided
  if (!model.devices) {
    setModel((p) => ({
      ...p,
      devices: formatDeviceOptions(itemConfig.devices),
    }));
  }
};

/**
 * Construct a list of device families given the runs_on_* data from the API
 * @param {Object} apiData - the library item data object
 * @returns a list of device family names, ex. ['Mac', 'iPhone', 'iPad', 'Apple TV']
 */

export const getDeviceFamiliesFromRunsOn = (apiData) =>
  Object.keys(devicesData).filter((device) => {
    // `runs_on_* must be true in the API response and the value in the
    // `devicesData` map must have a label (this excludes ipod and watch)
    return apiData[devicesData[device].commonKey] && devicesData[device].label;
  });

/**
 * Construct a list of selected device families in { label, value } format given library item API data.
 * @param {Object} apiData - the library item data object
 * @returns a list of selected device families in { label, value } format
 *   ex. [
 *         { label: 'Mac', value; 'Mac' },
 *         { label: 'iPhone, value: 'iPhone' },
 *         { label: 'Apple TV', value: 'Apple TV' },
 *       ]
 */
export const getSelectedDevicesFromRunsOn = (apiData) => {
  const filteredApiData = { ...apiData };
  if (!FeatureFlags.getFlag('ios-apple-vision-pro')) {
    delete filteredApiData.runs_on_vision;
  }

  const filteredDevicesData = getDeviceFamiliesFromRunsOn(filteredApiData);
  const selectedDeviceOptions = formatDeviceOptions(filteredDevicesData);
  return selectedDeviceOptions;
};

/**
 * Constructs an object mapping run_on_* keys to a true/false value depending
 * on whether or not each device is present in the given list of device names
 * @param {Object} model - a library item model that has a list of device options in a `devices` field
 * @returns an object mapping `run_on_*` keys to a true/false value
 *   ex. {
 *         runs_on_mac: true,
 *         runs_on_iphone: true,
 *         runs_on_ipad: false,
 *         runs_on_tv: true,
 *        }
 */
export const getRunsOnFromModel = (model) => {
  const runsOn = {};

  // Get selected devices from the `model` and default to an empty array if none are present
  const installOnDevices = model.devices?.map((option) => option.value) || [];

  deviceOptions.forEach((deviceOption) => {
    const runsOnKey = devicesData[deviceOption.value].commonKey;
    const shouldInstallOnDeviceOption = installOnDevices?.includes(
      deviceOption.value,
    );
    runsOn[runsOnKey] = shouldInstallOnDeviceOption;
  });

  return runsOn;
};
