import FeatureFlags from 'src/config/feature-flags';
import {
  getRunsOnFromModel,
  getSelectedDevicesFromRunsOn,
} from 'src/features/library-items/data-service/library-item/devices';
import { getAllBlueprintOptions } from '../../../data-service/blueprint/use-blueprint-service';
import configs from '../../../library/library-item-configurations/items/profile';

const visionFF = 'ios-apple-vision-pro';

function transformSettings(settings) {
  const transformCheckedField = (field) =>
    field !== undefined ? { checked: field } : undefined;

  const transformedSettings = {
    Mac: {
      ...settings.Mac,
      forcePIN: transformCheckedField(settings.Mac.forcePIN),
      allowSimple: transformCheckedField(settings.Mac.allowSimple),
      requireAlphanumeric: transformCheckedField(
        settings.Mac.requireAlphanumeric,
      ),
      changeAtNextAuth: transformCheckedField(settings.Mac.changeAtNextAuth),
      minLength: {
        ...settings.Mac.minLength,
        value:
          settings.Mac.minLength.value > 16 ? 16 : settings.Mac.minLength.value,
      },
    },
    iPhone: {
      ...settings.iPhone,
      allowSimple: transformCheckedField(settings.iPhone.allowSimple),
      forcePIN: transformCheckedField(settings.iPhone.forcePIN),
      requireAlphanumeric: transformCheckedField(
        settings.iPhone.requireAlphanumeric,
      ),
      minLength: {
        ...settings.iPhone.minLength,
        value:
          settings.iPhone.minLength.value > 16
            ? 16
            : settings.iPhone.minLength.value,
      },
    },
    iPad: {
      ...settings.iPad,
      allowSimple: transformCheckedField(settings.iPad.allowSimple),
      forcePIN: transformCheckedField(settings.iPad.forcePIN),
      requireAlphanumeric: transformCheckedField(
        settings.iPad.requireAlphanumeric,
      ),
      minLength: {
        ...settings.iPad.minLength,
        value:
          settings.iPad.minLength.value > 16
            ? 16
            : settings.iPad.minLength.value,
      },
    },
  };

  // Conditionally add Vision based on feature flag
  if (FeatureFlags.getFlag(visionFF)) {
    transformedSettings.Vision = {
      ...settings.Vision,
      allowSimple: transformCheckedField(settings.Vision.allowSimple),
      forcePIN: transformCheckedField(settings.Vision.forcePIN),
      requireAlphanumeric: transformCheckedField(
        settings.Vision.requireAlphanumeric,
      ),
      minLength: {
        ...settings.Vision.minLength,
        value:
          settings.Vision.minLength.value > 16
            ? 16
            : settings.Vision.minLength.value,
      },
    };
  }

  return transformedSettings;
}

async function transformFromApi(apiData) {
  const selectedBlueprints = await getAllBlueprintOptions(apiData.blueprints);
  const excludedBlueprints = await getAllBlueprintOptions(
    apiData.excluded_blueprints,
  );

  const commonData = {
    id: apiData.id,
    name: apiData.name,
    devices: getSelectedDevicesFromRunsOn(apiData),
    selectedBlueprints,
    excludedBlueprints,
    isAllBlueprints: apiData.is_all_blueprints,
  };

  // const result = await transformer(apiData, commonData);
  const result = {
    ...commonData,
    isActive: apiData.active,
    ...transformSettings(apiData.data),
  };

  result.rules = apiData.rules;

  return {
    ...apiData,
    data: result,
  };
}

function transformToApi(model) {
  const toSend = {
    name: model.name,
    active: model.isActive,
    blueprints: model.selectedBlueprints.map((b) => b.value),
    is_all_blueprints: model.isAllBlueprints,
    data: {
      Mac: getMacSettingsForApi(model.Mac),
      iPhone: getIPhoneSettingsForApi(model.iPhone),
      iPad: getIPadSettingsForApi(model.iPad),
    },
  };

  if (FeatureFlags.getFlag(visionFF)) {
    toSend.data.Vision = getVisionSettingsForApi(model.Vision);
  }

  // Add necessary properties when creating new Profile:
  if (!model.id) {
    const itemConfig = configs.Passcode;

    toSend.type = itemConfig.type;
    toSend.identifier = itemConfig.identifier;
  }

  toSend.rules = model.rules || null;

  toSend.skip_blueprint_conflict = model.skip_blueprint_conflict || false;

  // Populate `runs_on_*` fields
  const runsOn = getRunsOnFromModel(model);
  Object.keys(runsOn).forEach((runsOnKey) => {
    toSend[runsOnKey] = runsOn[runsOnKey];
  });

  return toSend;
}

function getMacSettingsForApi(settings) {
  return {
    ...settings,
    forcePIN: settings.forcePIN.checked,
    allowSimple: settings.allowSimple.checked,
    requireAlphanumeric: settings.requireAlphanumeric.checked,
    changeAtNextAuth: settings.changeAtNextAuth.checked,
  };
}

function getIPhoneSettingsForApi(settings) {
  return {
    ...settings,
    allowSimple: settings.allowSimple.checked,
    forcePIN: settings.forcePIN.checked,
    requireAlphanumeric: settings.requireAlphanumeric.checked,
  };
}

function getIPadSettingsForApi(settings) {
  return {
    ...settings,
    allowSimple: settings.allowSimple.checked,
    forcePIN: settings.forcePIN.checked,
    requireAlphanumeric: settings.requireAlphanumeric.checked,
  };
}

function getVisionSettingsForApi(settings) {
  return {
    ...settings,
    allowSimple: settings.allowSimple.checked,
    forcePIN: settings.forcePIN.checked,
    requireAlphanumeric: settings.requireAlphanumeric.checked,
  };
}

export { transformToApi, transformFromApi };
